<template>
  <div class="app-container">
    <!-- <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
      <el-tab-pane
        :label="$t('program.manager')"
        :name="programTab.tabName"
        :key="programTab.tabName"
      > -->
    <div class="filter-container">
      <el-button
        icon="el-icon-plus"
        type="primary"
        size="mini"
        @click="addProgram()"
      >
        {{ $t("program.addProgram") }}
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="programList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :label="$t('commons.index')"
        width="95"
      >
      </el-table-column>
      <el-table-column prop="programName" :label="$t('program.name')">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input
              v-model="scope.row.programName"
              maxlength="33"
              :show-word-limit="true"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </template>
          <span v-else>{{ scope.row.programName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('commons.status')" width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-select v-model="scope.row.status">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <span v-else>{{ scope.row.statusName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastUpdateDate"
        :label="$t('commons.lastUpdateDate')"
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.lastUpdateDate | dateFormat("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.actions')"
        width="160"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <div class="ruge-buttons">
              <el-button type="text" @click="updateProgram(scope.row)">{{
                $t("commons.save")
              }}</el-button>
              <el-button type="text" @click="cancelEditProgram(scope.row)">{{
                $t("commons.cancel")
              }}</el-button>
            </div>
          </template>
          <template v-else>
            <div class="ruge-buttons">
              <el-button type="text" @click="allotDimension(scope.row)">{{
                $t("program.dataDimension")
              }}</el-button>
              <el-button type="text" @click="editProgram(scope.row)">{{
                $t("commons.edit")
              }}</el-button>
              <el-button
                type="text"
                style="color: #ff0000"
                @click="deleteProgram(scope.row)"
                >{{ $t("commons.delete") }}</el-button
              >
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 增加数据范围 -->
    <el-dialog
      :visible.sync="editMode"
      width="30%"
      :before-close="quitAddProgram"
    >
      <template slot="title">
        <title-icon />{{ $t("program.addProgram") }}
      </template>
      <el-form
        label-position="top"
        :model="form"
        ref="programForm"
        :rules="programRule"
      >
        <el-form-item :label="$t('program.name')" prop="programName">
          <el-input
            v-model="form.programName"
            maxlength="33"
            :show-word-limit="true"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('commons.status')" prop="status">
          <el-select
            v-model="form.status"
            :placeholder="$t('commons.selectPlease')"
            style="width: 100%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="quitAddProgram()">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveProgram()">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- </el-tab-pane> -->
    <!-- <el-tab-pane
        v-if="choiceDimensionTab"
        :label="$t('program.dataDimension')"
        :name="choiceDimensionTab.tabName"
        :key="choiceDimensionTab.tabName"
        closable
      >
        <dimension-choice
          v-bind:programRow="currentRow"
          :key="currentRow.programId"
        ></dimension-choice>
      </el-tab-pane> 
    </el-tabs>-->
  </div>
</template>

<script>
let manager = require("@/api/ruge/security/program");
// import DimensionChoice from "@/views/ruge/program/tenant/dimensionChoice";

// const defineTab = {
//   programTab: {
//     tabName: "1",
//     tabTitle: "programTab",
//   },
//   choiceDimensionTab: {
//     tabName: "2",
//     tabTitle: "choiceDimensionTab",
//   },
// };

export default {
  name: "ProgramManager",
  // components: { DimensionChoice },
  data() {
    return {
      loading: true,
      // activeTabName: defineTab.programTab.tabName,
      // choiceDimensionTab: null,
      programList: null,
      editMode: false,
      options: [
        { value: 1, label: this.$t("commons.active") },
        { value: 2, label: this.$t("commons.unActive") },
      ],
      currentRow: {
        programId: null,
        programName: null,
        tenantId: null,
      },
      form: {
        programName: "",
        tenantId: null,
        status: 1,
      },
      programRule: {
        programName: [
          {
            required: true,
            trigger: ["change"],
            message: this.$t("validate.required"),
          },
        ],
        tenantId: [
          {
            required: true,
            trigger: ["change"],
            message: this.$t("validate.required"),
          },
        ],
        status: [
          {
            required: true,
            trigger: ["change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  created() {
    this.getProgramList();
  },
  computed: {
    programTab() {
      return defineTab.programTab;
    },
  },
  methods: {
    getProgramList() {
      this.loading = true;
      manager
        ._tenant_getProgramList()
        .then((response) => {
          this.programList = response.map((v) => {
            this.$set(v, "statusName", this.getStatusDisplay(v.status));
            this.setOrgProgram(v);
            return v;
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    editProgram(row) {
      this.$set(row, "edit", true);
    },
    deleteProgram(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          manager
            ._tenant_deleteProgram({
              tenantId: row.tenantId,
              programId: row.programId,
            })
            .then(() => {
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              this.getProgramList();
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    updateProgram(row) {
      manager
        ._tenant_updateProgram({
          programId: row.programId,
          programName: row.programName,
          status: row.status,
        })
        .then(() => {
          row.edit = false;
          this.setOrgProgram(row);
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    cancelEditProgram(row) {
      this.resetOrgProgram(row);
      row.edit = false;
    },
    getStatusDisplay(status) {
      let statusName;
      if (status == 1) {
        statusName = this.$t("commons.active");
      } else {
        statusName = this.$t("commons.unActive");
      }
      return statusName;
    },
    setOrgProgram(row) {
      row.orgProgramName = row.programName;
      row.orgStatus = row.status;
      row.statusName = this.getStatusDisplay(row.status);
    },
    resetOrgProgram(row) {
      row.programName = row.orgProgramName;
      row.status = row.orgStatus;
      row.statusName = this.getStatusDisplay(row.status);
    },
    addProgram() {
      this.editMode = true;
    },
    quitAddProgram() {
      this.$refs.programForm.resetFields();
      this.editMode = false;
    },
    saveProgram() {
      this.$refs.programForm.validate((valid) => {
        if (valid) {
          manager
            ._tenant_createProgram(this.form)
            .then(() => {
              this.editMode = false;
              this.form.programName = null;
              this.form.status = null;
              this.$message({
                type: "success",
                message: this.$t("message.saveSuccess"),
              });
              this.getProgramList();
            })
            .catch(() => {});
        }
      });
    },
    allotDimension(row) {
      // this.choiceDimensionTab = defineTab.choiceDimensionTab;
      // this.currentRow.programName = row.programName;
      // this.currentRow.programId = row.programId;
      // this.currentRow.tenantId = row.tenantId;
      // this.activeTabName = defineTab.choiceDimensionTab.tabName;
      this.$router.push({
        path: "/tenant/permission/program/allotPermission",
        query: {
          programName: row.programName,
          programId: row.programId,
          tenantId: row.tenantId,
        },
      });
    },
    // removeTab(tabName) {
    //   if (tabName == "2") {
    //     this.choiceDimensionTab = null;
    //   }
    //   this.activeTabName = defineTab.programTab.tabName;
    // },
  },
};
</script>
