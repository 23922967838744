var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-plus", type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.addProgram()
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("program.addProgram")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.programList,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              label: _vm.$t("commons.index"),
              width: "95",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "programName", label: _vm.$t("program.name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.edit
                      ? [
                          _c("el-input", {
                            attrs: {
                              maxlength: "33",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: scope.row.programName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "programName", $$v)
                              },
                              expression: "scope.row.programName",
                            },
                          }),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.programName))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$t("commons.status"),
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.edit
                      ? [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.statusName))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lastUpdateDate",
              label: _vm.$t("commons.lastUpdateDate"),
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            scope.row.lastUpdateDate,
                            "YYYY-MM-DD hh:mm:ss"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("commons.actions"),
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.edit
                      ? [
                          _c(
                            "div",
                            { staticClass: "ruge-buttons" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateProgram(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("commons.save")))]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEditProgram(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "ruge-buttons" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.allotDimension(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("program.dataDimension"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editProgram(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("commons.edit")))]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#ff0000" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteProgram(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("commons.delete")))]
                              ),
                            ],
                            1
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editMode,
            width: "30%",
            "before-close": _vm.quitAddProgram,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editMode = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(_vm._s(_vm.$t("program.addProgram")) + "\n    "),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "programForm",
              attrs: {
                "label-position": "top",
                model: _vm.form,
                rules: _vm.programRule,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("program.name"), prop: "programName" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "33",
                      "show-word-limit": true,
                      autocomplete: "off",
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.form.programName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "programName", $$v)
                      },
                      expression: "form.programName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("commons.status"), prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: _vm.$t("commons.selectPlease") },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.quitAddProgram()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveProgram()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }